export default {
  a11y: {
    'screen-reader-aid': 'Sprungmarken',
    'to-content': 'Zum Inhalt springen',
    'read-content': 'Artikel lesen',
  },
  logo: {
    home: 'Zur Startseite',
  },
  menu: {
    toggle: 'Menü',
    search: {
      placeholder: 'Suchen',
      label: 'Suchbegriff eingeben',
      submit: 'Suche starten...',
    },
    login: 'Anmelden',
    register: '1 Monat kostenlos lesen',
    register_md: 'Kostenlos testen',
    logout: 'Abmelden',
    myaccount: 'Mein Konto',
    mydata: 'Meine Daten',
  },
  paywall: {
    header: {
      title: 'Mehr wissen. Nichts verpassen. <i>VN.at</i>',
    },
    login: {
      button: 'Anmelden',
      register: 'Sie haben keinen Zugang? Jetzt testen!',
      'forgot-password': 'Passwort vergessen?',
    },
    register: {
      form: {
        title: 'Zugangsdaten festlegen',
        'sms-title': 'Verifizierungs-Code eingeben',
        'sms-subtitle':
          'Bitte geben Sie den <b>6-stelligen Code</b> ein, den wir Ihnen per SMS geschickt haben an: <b>{phone}</b>',
        firstname: 'Vorname',
        lastname: 'Nachname',
        phone: 'Mobil-Rufnummer (+43)',
        'phone-title': 'Beispiel: +436601212121',
        'phone-info':
          'Später können Sie Ihre Mobil-Rufnummer genau wie Ihre E-Mail-Adresse für den Login verwenden.',
        'phone-verification': 'Sie erhalten eine SMS zur Verifizierung',
        email: 'E-Mail-Adresse',
        'email-hint': {
          text: 'Diese E-Mail-Adresse existiert bereits. Bitte versuchen Sie  es mit der {action}.',
          link: 'Anmeldung',
        },
        password: 'Passwort anlegen',
        'sms-token': 'Code eingeben',
        button: 'Weiter',
        'sms-button': 'Bestätigen',
      },
      legal:
        'Es gelten die <a target="_blank" href= "https://www.vn.at/agb">AGB</a> und <a target="_blank" href="https://www.vn.at/datenschutz">Datenschutzbestimmungen</a> der Russmedia Verlag GmbH.',
      'to-upsell': 'Lesen Sie die gedruckte Zeitung? <b>Zum Aboshop</b>',
      'to-login': 'Sie haben bereits die VN-Digital oder VOL.AT? Hier anmelden',
      'wrong-phone': 'Falsche Handynummer eingegeben?',
    },
    activation: {
      button: 'Bestätigen',
    },
    'forgot-password': {
      'teaser-header': 'Passwort zurücksetzen',
      'teaser-text':
        'Sie erhalten einen Bestätigungslink per E-Mail zugesendet.',
      email: 'E-Mail-Adresse',
      button: 'Senden',
    },
    volintegration: {
      text: 'Ihre Daten werden mit <b>VOL.at</b> abgeglichen.<br>Dieser Vorgang kann einige Sekunden dauern.<br><b>Sie werden nach Abschluss automatisch weitergeleitet.</b>',
      tryagain: 'Erneut versuchen',
      reload: 'Seite neu laden',
      userhasaccess:
        'Ihr aktueller Benutzer hat bereits ein Abo auf VN.at. Sie wurden automatisch angemeldet. Bitte laden Sie die Seite neu.',
    },
  },
  'validate-password': {
    confirmation:
      'Ihr Passwort wurde erfolgreich zurückgesetzt. Sie erhalten in Kürze ein neues Passwort.',
  },
  taglist: {
    more: 'Mehr zum Thema',
  },
  related: {
    title: 'Das könnte sie auch interessieren...',
  },
  sharing: {
    facebook: 'Auf Facebook teilen',
    twitter: 'Auf X teilen',
    whatsapp: 'Auf Whatsapp teilen',
    messenger: 'Auf Facebook Messenger teilen',
    mail: 'Als E-Mail versenden',
    clipboard: 'Link kopieren',
    copied: 'Link kopiert',
  },
  dates: {
    prefix: 'am',
    format: "dd.MM.yyyy' • 'HH:mm 'Uhr'",
    nice: "'HEUTE • 'HH:mm 'Uhr'",
  },
  lists: {
    loading: 'lädt...',
    more: 'weiterer Inhalt wird geladen...',
  },
  epaper: {
    thisway: "Hier geht's <br>zum E-Paper",
    description: 'Hier klicken',
    aboshop: 'Zum Aboshop',
  },
  error: {
    404: {
      title: '<strong>404</strong> Seite nicht gefunden',
      text: 'Die von Ihnen angeforderte Seite konnte nicht gefunden werden',
    },
    generic: {
      title: '<strong>!!!</strong> Ein Fehler ist aufgetreten',
      text: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.',
    },
    backtohomepage: 'Zurück zur Homepage',
    'not-authorized': 'Sie benötigen ein Abo, um diesen Inhalt zu sehen.',
    'no-stream-in-preview':
      'In der Vorschau wird der Livestream nicht angezeigt',
    offline:
      'Sie sind offline. Bitte verbinden Sie ihr Gerät mit dem Internet und versuchen Sie es erneut.',
  },
  footer: {
    copyright: ' · Russmedia Verlag',
  },
  seo: {
    title: {
      category: {
        default: 'Aktuelle Nachrichten',
      },
      tags: 'Aktuelle Nachrichten',
      search: 'Aktuelle Nachrichten',
      all: 'Alle Kategorien',
      author: 'Artikel von {0}',
    },
    description: {
      category: {
        default:
          'Alle aktuellen News aus dem Ressort {0} schnell und übersichtlich. Hier verpassen Sie keine {0}-Nachrichten.',
      },
      tags: 'Alle aktuellen News zu #{0} schnell und übersichtlich. Hier verpassen Sie keine Nachrichten über #{0}.',
      search:
        'Alle aktuellen News zu „{0}“ schnell und übersichtlich. Hier verpassen Sie keine Nachrichten über „{0}“.',
      author:
        'Alle Artikel von {0} schnell und übersichtlich. Hier verpassen Sie keine Artikel von {0}.',
    },
  },
  search: {
    orderby: 'Sortiert nach',
    relevance: 'Relevanz',
    date: 'Datum',
  },
  weather: {
    today: 'Heute <span class="text-red">in</span>',
    placeholder: 'Wählen...',
    min: 'Min.',
    max: 'Max.',
    rain: 'Niederschlag',
    rain_unit: 'mm/m²',
    wind: 'Wind',
    wind_unit: 'km/h',
    'current-temp': 'Die aktuelle Temperatur in Bregenz beträgt ',
  },
  dropdown: {
    'no-results': 'Keine Ergebnisse',
  },
  newsletter: {
    'select-community': 'Gemeinde eingeben',
  },
  'picture-gallery': {
    open: 'Bilderserie öffnen ({0} Bilder)',
  },
}
